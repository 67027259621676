export const environment = {
  production: true,
  base: location.origin,
  api: ' https://gissv2-3548807.eiconbrasil.com.br',
  appId: '6d624ecd-527d-4296-a418-f77db493f503',
  versao: 'v2.00.M.20210204.01',
  frontGissV2: 'https://scs.giss.com.br/portal/#/login-portal',
  gissAjudaV2: 'https://scs.giss.com.br/giss-ajuda/',
  redirectUrl: '/'
};
